import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { uniqueId } from 'lodash';
import { useBooleanState } from '../../utilities/hooks';
import { IWExhibitorDto, PartnerSessionQuotasDto, SessionDto } from '../../models/Dtos';
import Modal from '../basics/Modal';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import { Button, Chip, Tooltip } from '@mui/material';
import { deleteSession, getExhibitor, getSessions } from '../../api/apis';

interface DateSessions {
    id: string;
    date: string;
    sessions: SessionDto[];
}

const eventDates = ['2025-06-11', '2025-06-12', '2025-06-13', '2025-06-14'];

const SessionsList = () => {
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const exhibitorId = searchParams.get('exhibitorId');
    const [sessions] = useState<DateSessions[]>(); // type any provisoire
    const [allSessions, setAllSessions] = useState<SessionDto[]>(); // type any provisoire
    const navigate = useNavigate();
    const [isOpenDeleteModal, openDeleteModal, closeDeleteModal] = useBooleanState();
    const [isFetchLoading, startFetchLoading, stopFetchLoading] = useBooleanState(true);
    const sessionToDelete = useRef<string>('');
    const [selectedSessionDate, setSelectedSessionDate] = useState<string>();
    const [partnerStatut, setPartnerStatut] = useState<PartnerSessionQuotasDto>();

    const changeLanguageHandler = (lang: string) => {
        i18n.changeLanguage(lang)
    }

    useEffect(() => {
        startFetchLoading();
        if (!sessions && exhibitorId) {
            try {
                const lastSelectedSessionState = sessionStorage.getItem(`selectedSessionDate-${exhibitorId}`);
                if (lastSelectedSessionState)
                    setSelectedSessionDate(lastSelectedSessionState);
                else
                    setSelectedSessionDate(eventDates[0] || '');
            } catch { }

            const promises = [
                getSessions(exhibitorId),
                getExhibitor(exhibitorId)
            ];
            Promise.all(promises).then((results) => {
                const curSessions = results[0].data as SessionDto[];
                setAllSessions(curSessions.filter(s => s.startDateTimeSlot));
                const partnerInfo = results[1].data as IWExhibitorDto;

                const dataStatut: PartnerSessionQuotasDto = {
                    name: partnerInfo.name,
                    highlightsTotal: 0,
                    sessionsPerDay: 0,
                    hashtags: 3,
                    partnerStatut: partnerInfo.sf_statut_partenaire__c!
                };

                switch (partnerInfo.sf_statut_partenaire__c) {
                    case "founding":
                        {
                            dataStatut.sessionsPerDay = 25;
                            dataStatut.hashtags = 5;
                            break;
                        }
                    case "countryoftheyear":
                    case "countryoftheyearebooth":
                    case "platinumebooth":
                        {
                            dataStatut.sessionsPerDay = 20;
                            dataStatut.hashtags = 5;
                            break;
                        }
                    case "goldebooth":
                        {
                            dataStatut.sessionsPerDay = 15;
                            dataStatut.hashtags = 5;
                            break;
                        }
                    case "silverebooth":
                        {
                            dataStatut.sessionsPerDay = 10;
                            dataStatut.hashtags = 5;
                            break;
                        }
                    case "territoriesebooth":
                    case "smpe":
                    case "smpebooth": // l'un des 2 n'est p-e pas bon
                        {
                            dataStatut.hashtags = 4;
                            break;
                        }
                    case "sponsorebooth":
                    case "startup":
                    case "pitchcontest":
                        {
                            dataStatut.hashtags = 3;
                            break;
                        }
                    case "parkebooth":
                        {
                            dataStatut.hashtags = 5;
                            break;
                        }
                }

                setPartnerStatut(dataStatut);
            }).finally(() => {
                stopFetchLoading();
            });
        }
    }, [sessions, exhibitorId]);

  /*   const highlightCount = useMemo(() => {
        return allSessions?.filter(s => s.highlight === true).length;
    }, [allSessions]); */

    const sessionsAllDaysOptions = useMemo(() => {
        return eventDates.map((d) => {
            //const nbSessions = allSessions ? allSessions.filter(s => s.startDateTimeSlot?.startsWith(`${d}T`)).length : 0;
            const dParts = d.split('-');
            return {
                value: d,
                label: dParts[2]
            }
        });
    }, []); //allSessions, t

    const sessionsWed = useMemo(() => {
        if (!allSessions) return 0;
        return allSessions.filter(s => s.startDateTimeSlot?.startsWith(`${eventDates[0]}T`) && s.typologieDeSession !== "workshop").length;
    }, [allSessions]);

    const sessionsThu = useMemo(() => {
        if (!allSessions) return 0;
        return allSessions.filter(s => s.startDateTimeSlot?.startsWith(`${eventDates[1]}T`) && s.typologieDeSession !== "workshop").length;
    }, [allSessions]);

    const sessionsFri = useMemo(() => {
        if (!allSessions) return 0;
        return allSessions.filter(s => s.startDateTimeSlot?.startsWith(`${eventDates[2]}T`) && s.typologieDeSession !== "workshop").length;
    }, [allSessions]);

    const sessionsSat = useMemo(() => {
        if (!allSessions) return 0;
        return allSessions.filter(s => s.startDateTimeSlot?.startsWith(`${eventDates[3]}T`) && s.typologieDeSession !== "workshop").length;
    }, [allSessions]);

    const sessionsQuotaForSelectedDay = useMemo(() => {
        let curNbSessions = 0;
        if (selectedSessionDate && allSessions) {
            curNbSessions = allSessions.filter(s => s.startDateTimeSlot?.startsWith(`${selectedSessionDate}T`) && s.typologieDeSession !== "workshop").length
        }
        return curNbSessions;
    }, [allSessions, selectedSessionDate]);

    const sessionsByDay = useMemo(() => {
        if (!allSessions) return [];
        const datesSessions = allSessions.filter(s => !!s.startDateTimeSlot).reduce((acc: DateSessions[], current: SessionDto) => {
            const curdate = current.startDateTimeSlot.substring(0, 10);
            if (!acc.some(s => s.date === curdate)) {
                acc.push({
                    id: uniqueId(),
                    date: curdate,
                    sessions: [current]
                });
            } else {
                acc.find(s => s.date === curdate)?.sessions.push(current);
            }

            return acc;
        }, []);

        return datesSessions;
    }, [allSessions]);

    const sessionsForSelectedDay = useMemo(() => {
        const curSessions = sessionsByDay.find(d => d.date === selectedSessionDate)?.sessions;
        return curSessions || [];
    }, [sessionsByDay, selectedSessionDate]);

    const handleEditClick = useCallback((session: any, e: MouseEvent<HTMLAnchorElement>) => {
        if (allSessions) {
            e.preventDefault();
            navigate(`${e.currentTarget.pathname}${e.currentTarget.search}`,
                {
                    state: {
                        session,
                        //highlightCount,
                        partnerStatut,
                        allSessions
                    }
                });
        }
    }, [navigate, allSessions, partnerStatut]); //highlightCount, 

    const handleDeleteClick = useCallback(async (id: string) => {
        openDeleteModal();
        sessionToDelete.current = id;
    }, [openDeleteModal]);

    const handleSelectSessionDate = useCallback((newValue: any) => {
        const newSelectedDate = newValue; // (newValue && newValue.currentTarget) ? newValue.currentTarget.value : undefined;
        setSelectedSessionDate(newSelectedDate);
        try {
            if (newSelectedDate)
                sessionStorage.setItem(`selectedSessionDate-${exhibitorId}`, newSelectedDate);
            else
                sessionStorage.removeItem(`selectedSessionDate-${exhibitorId}`);
        } catch { }
    }, [exhibitorId]);

    const canAddSession = useMemo(() => {
        return partnerStatut &&
            (sessionsWed < partnerStatut.sessionsPerDay ||
                sessionsThu < partnerStatut.sessionsPerDay ||
                sessionsFri < partnerStatut.sessionsPerDay ||
                sessionsSat < partnerStatut.sessionsPerDay);
    }, [partnerStatut, sessionsWed, sessionsThu, sessionsFri, sessionsSat]);

    const handleDeleteConfirm = async () => {
        closeDeleteModal();
        if (allSessions && exhibitorId) {
            try {
                await deleteSession(exhibitorId, sessionToDelete.current);

                const session = allSessions.find(s => s.id === sessionToDelete.current);
                const newSessions = allSessions.filter(s => s.id !== sessionToDelete.current);

                setAllSessions(newSessions);
                toast.success(`${t('sessionForm.successes.delete')} (${session?.title})`);
            } catch {
                toast.error(t('sessionForm.errors.delete'));
            }
        }
    };

    return (
        <div className="bloc-iframe">
            <h2>{t('sessions.title')}</h2>
            <button
                className="language-switcher"
                type="button"
                onClick={() => changeLanguageHandler(i18n.language === "en" ? "fr" : "en")}>{t("sessions.switchLanguage")}</button>
            {isFetchLoading ? (
                <TailSpin
                    color="#1d6ff7"
                    height={50}
                    width={50}
                    wrapperClass="loader margin"
                />
            ) : (<>
                <div> 
                    <div className='flex-container sessions-dates'>
                    {
                        sessionsAllDaysOptions.map((d) => (<div key={d.value} className="sessions-date">
                            <Button 
                                variant={selectedSessionDate === d.value ? "text" : "outlined"}
                                className={selectedSessionDate === d.value ? "selected" : ""}
                                onClick={() => handleSelectSessionDate(d.value)}>
                                    <span className='month'>{t(`common.months.06`)}</span> <span className='day'>{d.label}</span>
                            </Button>
                        </div>))
                    }
                    </div>
                    {allSessions && allSessions.length > 0 && (
                            <div>
                                {selectedSessionDate && (<>
                                    { partnerStatut && (<div className='flex-container gap' style={{ justifyContent: "center", marginBottom: 20 }}>
                                        <div className="daily-quota shadow-vvt-2">
                                            <h4>{t('sessions.dailyQuota')}</h4>
                                            <hr className="gradient-vvt2" />
                                            <div className="quota">
                                                <span className="current-quota">{allSessions ? allSessions.filter(s => s.startDateTimeSlot?.startsWith(`${selectedSessionDate}T`)).length : 0}</span><span className="max-quota">/{partnerStatut.sessionsPerDay}</span>
                                            </div>
                                        </div>
                                    </div>)}
                                    <div className='flex-container gap' style={{ alignItems: "center", marginBottom: 20 }}>
                                        {partnerStatut && (
                                            <div>
                                                {/* <div>{t('sessionForm.highlight')}: {highlightCount}/{partnerStatut.highlightsTotal}</div> */}
                                                {partnerStatut.sessionsPerDay > 0 && (<div>
                                                    {sessionsQuotaForSelectedDay === partnerStatut.sessionsPerDay && (<h4>{t('sessions.sessionsQuotaReachedForSelectedDay')}</h4>)}                                                    
                                                </div>)}
                                            </div>
                                        )}
                                    </div>
                                </>)}
                            </div>
                        )}
                    {allSessions &&
                        <div className='flex-container gap' style={{ marginBottom: 20, alignItems: "center", justifyContent: "right" }}>
                            <div>
                                {!canAddSession ? (
                                    <span>{t("sessions.quotasReached")}</span>
                                ) : (
                                    <Link 
                                        className="button maintheme" 
                                        role='button'
                                        to={`/session/form?exhibitorId=${exhibitorId}`} 
                                        state={{
                                            //highlightCount,
                                            partnerStatut,
                                            allSessions
                                        }}>
                                        {t('common.addSession')}
                                        <i className="fas fa-plus-circle"></i>
                                    </Link>
                                )}</div>
                        </div>
                    }
                    <div>
                        {allSessions && allSessions.length > 0 && (
                            <div>
                                {selectedSessionDate && (<>
                                    {sessionsForSelectedDay.length > 0 ? (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th style={{  width: 'auto' }}>
                                                        <span>{t('sessions.name')}</span>
                                                    </th>
                                                    <th style={{ width: 165 }}>
                                                        <span>{t('sessions.timeslots')}</span>
                                                    </th>
                                                    {/* <th style={{ width: 50 }}>
                                                    </th> */}
                                                    {/* <th style={{ width: 120 }}>
                                                        <span>{t('sessions.sessionFormat')}</span>
                                                    </th> */}
                                                    <th style={{ width: 150 }}>
                                                        <span>{t('sessionForm.category')}</span>
                                                    </th>
                                                    <th style={{ width: 150 }}>
                                                        <span>{t('sessions.status')}</span>
                                                    </th>
                                                    <th>
                                                        <span>{t('sessions.statusComments')}</span>
                                                    </th>
                                                    <th className='center' style={{ width: 120 }}>
                                                        <span>{t('sessions.actions')}</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sessionsForSelectedDay.sort((a, b) => new Date(a.startDateTimeSlot).getTime() - new Date(b.startDateTimeSlot).getTime()).map(row => (
                                                    <tr key={row.id!}>
                                                        <td>{row.title}</td>
                                                        <td>{row.startDateTimeSlot.substring(11, 16)} - {row.endDateTimeSlot.substring(11, 16)}</td>                                                        
                                                        {/* <td>
                                                            {row.highlight && <i className="fas fa-star spaced" title={t('sessions.highlight')}></i>}
                                                        </td> */}
                                                        {/* <td>
                                                            {row.sessionFormat && (<span>{t(`sessionForm.sessionFormats.${row.sessionFormat}`)}</span>)}
                                                        </td> */}
                                                        <td>
                                                            {row.typologieDeSession && (<span>{t(`sessionForm.categories.${row.typologieDeSession}`)}</span>)}
                                                        </td>
                                                        <td className="session-status">
                                                            {row.status === 'Published' ? <Tooltip title={t('sessionForm.approved.tooltip') || ''}><span className='status approved'>{t('sessionForm.approved.title')}</span></Tooltip> : ''}
                                                            {row.status === 'Preview' ? <Tooltip title={t('sessionForm.waitingForApproval.tooltip') || ''}><span className='status review'>{t('sessionForm.waitingForApproval.title')}</span></Tooltip> : ''}
                                                            {(row.status === 'Draft' && row.commentairesVt) ? <Tooltip arrow={true} title={`${t('sessionForm.rejected.tooltip')}: ${row.commentairesVt}`}><span className='status approved'>{t('sessionForm.rejected.title')}</span></Tooltip> : ''}
                                                            {(row.status === 'Draft' && !row.commentairesVt) ? <Tooltip title={t('sessionForm.draft.tooltip') || ''}><span className='status drafted'>{t('sessionForm.draft.title')}</span></Tooltip> : ''}
                                                        </td>
                                                        <td>{(row.status === 'Draft' && row.commentairesVt) ? <span className="formError">{row.commentairesVt}</span> : ''}</td>
                                                        <td className="center session-actions">
                                                            {row.status === "Preview" ? (
                                                                <a href={`/session/form?exhibitorId=${exhibitorId}`} onClick={handleEditClick.bind(null, row)} title={t('common.view')}>
                                                                    <i className="fas fa-eye" title={t('common.view')}></i>
                                                                </a>
                                                            ) : (
                                                                <a href={`/session/form?exhibitorId=${exhibitorId}`} onClick={handleEditClick.bind(null, row)} title={t('common.edit')}>
                                                                    <i className="fas fa-pen" title={t('common.edit')}></i>
                                                                </a>
                                                            )}
                                                            <button type="button" onClick={handleDeleteClick.bind(null, row.id!)} title={t('common.delete')}>
                                                                <i className="fa-solid fa-xmark"></i>
                                                                <i className="fas fa-trash" title={t('common.delete')}></i>
                                                            </button>
                                                        </td>

                                                    </tr>)
                                                )}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div className="text-center padding">
                                            <b>{t('sessions.noSessionsForThisDay')}</b>
                                        </div>
                                    )}
                                </>)}
                            </div>
                        )}
                        {allSessions && allSessions.length === 0 && (
                            <p className="text-center padding">
                                {t('sessions.noSessions')}
                            </p>
                        )}
                    </div>
                </div>
                {isOpenDeleteModal && (
                    <Modal>
                        <p>{t('sessionForm.deleteConfirm')}</p>
                        <div className="buttons">
                            <button className="button button-light" onClick={closeDeleteModal} data-value={<><i className="fas fa-times"></i>
                                {t('common.no')}</>}>
                                <i className="fas fa-times"></i>
                                {t('common.no')}
                            </button>
                            <button className="button maintheme" onClick={handleDeleteConfirm}>
                                <i className="fas fa-check"></i>
                                {t('common.yes')}
                            </button>
                        </div>
                    </Modal>
                )}
            </>)}
        </div>
    )
}

export default SessionsList;