import React, { FormEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    useTable,
    useFilters,
    Row,
    Column,
} from 'react-table';
import xlsx from 'xlsx';
import FileInput from '../basics/FileInput';
import Button from '../basics/Button';
import Select from 'react-select';
import { selectStyles } from '../../constants/styles';
import { StartupPartnerDto, IWExhibitorDto, CreateStartupRequestDto, IStartupFormState } from '../../models/Dtos';
import { getPresenceArrayFromDto } from '../../utilities/utilities';
import { toast } from 'react-toastify';
import { useBooleanState } from '../../utilities/hooks';
import Modal from '../basics/Modal';
import { TailSpin } from 'react-loader-spinner';
import { Source } from '../../enums/Source';
import { createStartup, deleteStartup, getExhibitor, getStartupAffiliatedTo, getStartupChildren } from '../../api/apis';

const StartupsDashboard = () => {
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const exhibitorId = searchParams.get('exhibitorId');
    const [exhibitor, setExhibitor] = useState<IWExhibitorDto>();
    const [startups, setStartups] = useState<StartupPartnerDto[]>([]);
    const [fileImport, setFileImport] = useState<File | null>(null);
    const [isOpenDeleteModal, openDeleteModal, closeDeleteModal] = useBooleanState();
    const navigate = useNavigate();
    const startupToDelete = useRef<string>('');
    const [isFetchLoading, startFetchLoading, stopFetchLoading] = useBooleanState();
    const [isImportLoading, startImportLoading, stopImportLoading] = useBooleanState();
    const [areStartupsFetched, setStartupsFetched] = useState<boolean>(false);

    const changeLanguageHandler = (lang: string) => {
        i18n.changeLanguage(lang)
    };

    const currentStartupsPublished = useMemo(() => {
        if (startups)
            return startups.filter(s => s.spExhibitorId && s.spStatus !== 'Draft' && s.spStatus !== 'Rejected'&& s.spStatus !== 'Preview').length;
        else
            return 0;
    }, [startups]);

    const currentStartupsWaitingForApproval = useMemo(() => {
        if (startups)
            return startups.filter(s => s.spExhibitorId && s.spStatus === 'Preview').length;
        else
            return 0;
    }, [startups]);

    const canPublishNewStartup = useCallback(() => {
        if (exhibitor?.sf_startups__c && exhibitor.sf_startups__c - (currentStartupsPublished + currentStartupsWaitingForApproval) <= 0) {
            return 0;
        }
        return 1;
    }, [exhibitor, currentStartupsPublished, currentStartupsWaitingForApproval]);

    const canPublishEditStartup = useCallback((startup: StartupPartnerDto) => {
        if (!exhibitor?.sf_startups__c) {
            return 0;
        }
        if (startup.spStatus === "Published") {
            return 1;
        }
        if (startup.spStatus === "Draft" && exhibitor?.sf_startups__c - (currentStartupsPublished + currentStartupsWaitingForApproval) > 0) {
            return 1;
        }
        return 0;
    }, [exhibitor, currentStartupsPublished, currentStartupsWaitingForApproval]);

    const canEditStartup = (startup: StartupPartnerDto) => {
        if (startup.spStatus === "Preview") {
            return 0;
        }
        return 1;
    };

    const currentStartupsInDraft = useMemo(() => {
        if (startups)
            return startups.filter(s => !s.spExhibitorId).length;
        else
            return 0;
    }, [startups]);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows: Row<StartupPartnerDto>[], id: any, filterValue: string) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            }
        }),
        []
    )

    const DefaultColumnFilter = useCallback(({
        column: {
            filterValue,
            preFilteredRows,
            setFilter
        },
    }: {
        column: {
            filterValue: string,
            preFilteredRows: Row<StartupPartnerDto>[],
            setFilter: (value?: string) => void
        }
    }) => {
        return (
            <input
                className="input"
                value={filterValue || ''}
                placeholder={t("startupForm.filterByName")}
                onChange={e => {
                    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                }}
            />
        )
    }, [t]);

    function SelectColumnFilter({
        column: {
            filterValue,
            setFilter,
            preFilteredRows,
            id
        },
    }: {
        column: {
            filterValue: string,
            setFilter: (value?: string) => void,
            preFilteredRows: Row<StartupPartnerDto>[],
            id: string
        }
    }) {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = React.useMemo(() => {
            const options = new Set()
            preFilteredRows.forEach(row => {
                options.add(row.values[id])
            })
            return [...options.values()]
        }, [id, preFilteredRows])

        const statusLabel = React.useCallback((statusCode) => {
            if (statusCode) {
                return t(`startupForm.status.${statusCode}`);
            }
            return '';
        }, []);

        // Render a multi-select box
        return (
            <>
                <Select
                    value={{ value: filterValue, label: filterValue }}
                    options={[
                        { value: '', label: t('common.statusValues.all') },
                        ...options.map((option: any) => ({
                            value: option,
                            label: statusLabel(option)
                        }))
                    ]}
                    onChange={value => {
                        setFilter(value?.value || undefined)
                    }}
                    styles={selectStyles}
                />
            </>
        )
    }

    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter
        }),
        [DefaultColumnFilter]
    )

    const handleEditClick = useCallback((startup: StartupPartnerDto, e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        navigate(`${e.currentTarget.pathname}${e.currentTarget.search}&canPublish=${canPublishEditStartup(startup)}&canSave=${canEditStartup(startup)}`, {
            state: {
                existingStartupNames: startups?.map(s => s.spName.toLowerCase()),
                startup,
            } as IStartupFormState
        });
    }, [navigate, startups, canPublishEditStartup]);

    const handleDeleteClick = useCallback(async (id: string) => {
        openDeleteModal();
        startupToDelete.current = id;
    }, []);

    const handleRequestChangeClick = useCallback((startup: StartupPartnerDto, e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        navigate(`${e.currentTarget.pathname}${e.currentTarget.search}&canPublish=${canPublishEditStartup(startup)}&canSave=${canEditStartup(startup)}`, {
            state: {
                existingStartupNames: startups?.map(s => s.spName.toLowerCase()),
                startup,
            } as IStartupFormState
        });
    }, [navigate, startups, canPublishEditStartup]);

    const handleDeleteConfirm = async () => {
        closeDeleteModal();
        try {
            await deleteStartup(startupToDelete.current);
            const startupDeleted = startups.find(s => s.spId === startupToDelete.current);
            setStartups(startups.filter(s => s.spId !== startupToDelete.current));
            toast.success(`${t('dashboard.successes.delete')} (${startupDeleted?.spName})`);
        } catch {
            toast.error(t('dashboard.errors.delete'));
        }
    };

    const columns: Column<StartupPartnerDto>[] = React.useMemo(
        () => [
            { accessor: 'spId' },
            { accessor: 'spExhibitorId' },
            { accessor: 'spPresenceOnline' },
            { accessor: 'spPresenceWednesday' },
            { accessor: 'spPresenceThursday' },
            { accessor: 'spPresenceFriday' },
            { accessor: 'spPresenceSaturday' },
            { accessor: 'spContactLastName' },
            { accessor: 'spContactFirstName' },
            { accessor: 'spContactEmail' },
            { accessor: 'spContactPhone' },
            { accessor: 'spLanguage' },
            { accessor: 'spCountry' },
            { accessor: 'spStand' },
            { accessor: 'spValidatedBadges' },
            { accessor: 'spBadgeQuota' },
            { accessor: 'spAttendeeInvitationsSent' },
            { accessor: 'spFormule' },
            { accessor: 'spLogo' },
            { accessor: 'spCommunication' },
            { accessor: 'spSource' },
            { accessor: 'spExhibit' },
            { accessor: 'spOldName' },
            {
                Header: `${t('common.startup')}`,
                accessor: 'spName',
                minWidth: 250,
                width: 'auto',
                Cell: (value: any) => (
                    <span>{value.cell.row.values.spName} { value.cell.row.values.spOldName && <>({value.cell.row.values.spOldName})</> }</span>
                )
            },
            {
                Header: `${t('common.contactinfo')}`,
                minWidth: 200,
                width: 'auto',
                Cell: (value: any) => (
                    <>{value.cell.row.values.spContactLastName} {value.cell.row.values.spContactFirstName}<br />
                        {value.cell.row.values.spContactEmail}</>

                )
            },
            {
                Header: `${t('common.stand')}`,
                minWidth: 80,
                width: 80,
                Cell: (value: any) => (
                    <>{value.cell.row.values.spStand}</>
                )
            },
            {
                Header: `${t('common.presence')}`,
                minWidth: 100,
                width: 'auto',
                Cell: (value: any) => (
                    <>
                        {getPresenceArrayFromDto(value.cell.row.values)
                            .map(d => t(`common.daysPresenceShort.${d}`))
                            .join(', ')}
                    </>
                )
            },
            {
                Header: ` `,
                minWidth: 60,
                width: 60,
                Cell: (value: any) => (
                    <>
                        {value.cell.row.values.spStatus === "Published" && (<i className="fas fa-address-card" style={{ color: value.cell.row.values.spCommunication ? 'green' : 'red' }} title={t(`common.communication.${value.cell.row.values.spCommunication ? 'ok' : 'ko'}`)}></i>)}
                        { // we exclude supartner as they will not have a signboard
                        value.cell.row.values.spStatus === "Published" && value.cell.row.values.spFormule?.indexOf('supartner') < 0 && getPresenceArrayFromDto(value.cell.row.values).filter(d => d !== "online").length > 0 && (<i className="fas fa-brush" style={{ marginLeft: 10, color: value.cell.row.values.spExhibit ? 'green' : 'red' }} title={t(`common.exhibit.${value.cell.row.values.spExhibit ? 'ok' : 'ko'}`)}></i>)}
                    </>
                )
            },
            {
                Header: `${t('dashboard.validatedBadges')}`,
                minWidth: 80,
                width: 80,
                Cell: (value: any) => (
                    <>{value.cell.row.values.spValidatedBadges}</>
                )
            },
            {
                Header: `${t('dashboard.badgesQuota')}`,
                minWidth: 80,
                width: 80,
                Cell: (value: any) => (
                    <>{value.cell.row.values.spBadgeQuota}</>
                )
            },
            {
                Header: `${t('common.logo')}`,
                minWidth: 60,
                width: 60,
                Cell: (value: any) => (
                    <>
                        {value.cell.row.values.spLogo && (<a href={value.cell.row.values.spLogo} target="_blank" rel="noreferrer noopener"><i className='fas fa-download'></i></a>)}
                    </>
                )
            },
            {
                Header: (exhibitor && exhibitor.ag_Challenges && exhibitor.ag_Challenges.length > 0) ? `${t('common.challenge')}` : '',
                minWidth: (exhibitor && exhibitor.ag_Challenges && exhibitor.ag_Challenges.length > 0) ? 90 : 0,
                width: (exhibitor && exhibitor.ag_Challenges && exhibitor.ag_Challenges.length > 0) ? 90 : 0,
                id: 'challenge',
                Cell: (value: any) => (
                    <>
                        <div>{(exhibitor && exhibitor.ag_Challenges && exhibitor.ag_Challenges.length > 0 && value.cell.row.values.spSource === 'Agorize') && <i className="fas fa-trophy gold" title={t('common.challenge')}></i> }</div>
                    </>
                )
            },
            {
                Header: `${t('common.status')}`,
                accessor: 'spStatus',
                Filter: SelectColumnFilter,
                minWidth: 120,
                width: 'auto',
                Cell: (value: any) => (
                    <>
                        {t(`startupForm.status.${value.cell.row.values.spStatus}`)}
                    </>
                )
            },
            {
                id: 'actions',
                minWidth: 120,
                width: 120,
                Header: <div style={{ textAlign: 'right', marginRight: 20 }}>{t('common.actions')}</div>,
                Cell: (value: any) => {
                    const rowValues = value.cell.row.values;
                    return (
                        <div className="buttons startup-actions">
                            {((!rowValues.spExhibitorId || rowValues.spStatus !== 'Preview') && rowValues.spStatus !== 'Rejected') && (
                                <a href={`/startup/form?exhibitorId=${exhibitorId}`} onClick={handleEditClick.bind(null, rowValues)} title={t('common.edit')}>
                                    <i className="fas fa-pen" title={t('common.edit')}></i>
                                </a>
                            )}
                            {((!rowValues.spExhibitorId && rowValues.spStatus === 'Draft') || rowValues.spStatus === 'Rejected') && (
                                <button type="button" onClick={handleDeleteClick.bind(null, rowValues.spId)} title={t('common.delete')}>
                                    <i className="fas fa-trash" title={t('common.delete')}></i>
                                </button>
                            )}
                            {(rowValues.spExhibitorId && rowValues.spStatus !== 'Draft' && rowValues.spStatus !== 'Rejected') && (<>
                                <a href={`/startup/requestchange?exhibitorId=${exhibitorId}`} onClick={handleRequestChangeClick.bind(null, rowValues)} title={t("dashboard.requestChange")}>
                                    <i className="fas fa-cog"></i>
                                </a>
                            </>)}
                        </div>
                    )
                }
            }
        ],
        [
            exhibitorId,
            handleEditClick,
            handleDeleteClick,
            handleRequestChangeClick,
            t
        ]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        {
            columns,
            data: startups,
            defaultColumn,
            filterTypes,
            initialState: {
                hiddenColumns: [
                    'spId',
                    'spExhibitorId',
                    'spPresenceOnline',
                    'spPresenceWednesday',
                    'spPresenceThursday',
                    'spPresenceFriday',
                    'spPresenceSaturday',
                    'spContactLastName',
                    'spContactFirstName',
                    'spContactEmail',
                    'spContactPhone',
                    'spLanguage',
                    'spCountry',
                    'spStand',
                    'spValidatedBadges',
                    'spAttendeeInvitationsSent',
                    'spBadgeQuota',
                    'spLogo',
                    'spExhibit',
                    'spOldName',
                    'spSource',
                    'spCommunication',
                    'spUpdated',
                    'spFormule'
                ]
            }
        },
        useFilters
    );

    useEffect(() => {
        if (exhibitorId) {
            startFetchLoading();
            Promise.all([
                getStartupChildren(exhibitorId),
                getStartupAffiliatedTo(exhibitorId),
                getExhibitor(exhibitorId)
            ])
                .then(result => {
                    const startupsChildren = result[0].data;
                    const startupsAffiliatedTo = result[1].data;
                    const curExhibitor = result[2].data;
                    startupsChildren.forEach(c => {
                        if (c.spExhibitorId) {
                            const matchingStartup = startupsAffiliatedTo.find((a) => a.spExhibitorId === c.spExhibitorId.toLowerCase());
                            if (matchingStartup) {
                                c.spStatus = matchingStartup.spStatus;
                                // Rejected 
                                if (matchingStartup.spStatus === "Draft") c.spStatus = "Rejected";
                                c.spStand = matchingStartup.spStand;
                                c.spLogo = matchingStartup.spLogo;
                                c.spExhibit = matchingStartup.spExhibit;
                                c.spCommunication = matchingStartup.spCommunication;
                                c.spValidatedBadges = matchingStartup.spValidatedBadges;
                                c.spBadgeQuota = matchingStartup.spBadgeQuota;
                                c.spAttendeeInvitationsSent = matchingStartup.spAttendeeInvitationsSent;

                                // We use published values if not draft
                                if (c.spStatus !== "Draft") {
                                    c.spPresenceOnline = matchingStartup.spPresenceOnline;
                                    c.spPresenceWednesday = matchingStartup.spPresenceWednesday;
                                    c.spPresenceThursday = matchingStartup.spPresenceThursday;
                                    c.spPresenceFriday = matchingStartup.spPresenceFriday;
                                    c.spPresenceSaturday = matchingStartup.spPresenceSaturday;
                                    c.spFormule = matchingStartup.spFormule;
                                    if (matchingStartup.spName && matchingStartup.spName !== c.spName) {
                                        c.spOldName = c.spName.trim();
                                        c.spName = matchingStartup.spName;
                                    }
                                    if (matchingStartup.spContactEmail && matchingStartup.spContactEmail !== c.spContactEmail) c.spContactEmail = matchingStartup.spContactEmail;
                                    if (matchingStartup.spContactFirstName && matchingStartup.spContactFirstName !== c.spContactFirstName) c.spContactFirstName = matchingStartup.spContactFirstName;
                                    if (matchingStartup.spContactLastName && matchingStartup.spContactLastName !== c.spContactLastName) c.spContactLastName = matchingStartup.spContactLastName;
                                    if (matchingStartup.spContactPhone && matchingStartup.spContactPhone !== c.spContactPhone) c.spContactPhone = matchingStartup.spContactPhone;
                                    // override from Master
                                    if (matchingStartup.spMaster) {
                                        c.spName = matchingStartup.spMaster.spName;
                                        c.spCommunication = matchingStartup.spMaster.spCommunication;
                                        //c.spExhibit = matchingStartup.spMaster.spExhibit;
                                        if (matchingStartup.spMaster.spContactEmail) {
                                            c.spContactEmail = matchingStartup.spMaster.spContactEmail;
                                            c.spContactFirstName = matchingStartup.spMaster.spContactFirstName;
                                            c.spContactLastName = matchingStartup.spMaster.spContactLastName;
                                            c.spContactPhone = matchingStartup.spMaster.spContactPhone;
                                        }
                                        c.spLogo = matchingStartup.spMaster.spLogo;
                                    }
                                }
                            }
                            else {
                                c.spExhibitorId = "";
                                c.spStatus = "Rejected";
                            }
                        }
                    });
                    // don't sort by date, but by name
                    //startupsChildren.sort((a, b) => new Date(a.spUpdated).getTime() - new Date(b.spUpdated).getTime());

                    setExhibitor(curExhibitor);
                    setStartups(startupsChildren);
                    setStartupsFetched(true);
                })
                .catch(() => {
                    toast.error(t('dashboard.errors.fetch'));
                })
                .finally(stopFetchLoading);
        }
    }, [
        exhibitorId,
        t
    ]);

    const getFileData = (file: File) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => resolve(new Uint8Array(reader.result as ArrayBuffer));
        reader.onerror = error => reject(error);
    });

    const getSheetData = (sheet: xlsx.WorkSheet) => {
        if (!sheet["!ref"]) return [];
        const range = xlsx.utils.decode_range(sheet["!ref"]);
        const labels = [
            'socialReason',
            'online',
            'wed',
            'thu',
            'fri',
            'sat',
            'country',
            'firstName',
            'lastName',
            'mail',
            'phoneNumber'
        ];
        const data: any[] = [];
        const loweredNames: string[] = [];
        let skippedRows = 0;

        rowsLoop:
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const val = sheet[xlsx.utils.encode_cell({ c: C, r: R })];
                if (!val || val.v === '' || R < 2) continue;
                const rowIndex = R - 2 - skippedRows;

                // Skip row if startup already exists
                if (C === 0) {
                    const loweredValue = val.v.toLowerCase();
                    if (startups.some(s => s.spName.toLowerCase() === loweredValue) || loweredNames.some(s => s === loweredValue)) {
                        skippedRows++;
                        continue rowsLoop;
                    }
                    else {
                        loweredNames.push(loweredValue);
                    }
                }

                if (!data[rowIndex]) {
                    data[rowIndex] = {};
                }

                // If columns related to presence days
                if (C > 0 && C < 6) {
                    if (!data[rowIndex].daysPresences) {
                        data[rowIndex].daysPresences = [];
                    }
                    if (val.v === 'Yes') {
                        data[rowIndex].daysPresences?.push(labels[C]);
                    }
                }
                // If columns related to contact
                else if (C > 5) {
                    if (!data[rowIndex].contact) {
                        data[rowIndex].contact = {};
                    }
                    const trimmedVal = `${val.v}`.trim();
                    if (C === 9) // email, check validity
                    {
                        if (trimmedVal.match(/^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/gm)) 
                            data[rowIndex].contact[labels[C]] = trimmedVal;
                        else
                            console.log(`${trimmedVal} is not a valid email`)
                    }
                    else 
                        data[rowIndex].contact[labels[C]] = trimmedVal;
                }
                else {
                    data[rowIndex][labels[C]] = val.v;
                }
            }
        }
        return data.filter(r => !!r);
    }

    const getOldSheetData = (sheet: xlsx.WorkSheet) => {
        if (!sheet["!ref"]) return [];
        const range = xlsx.utils.decode_range(sheet["!ref"]);
        const labels = [
            'socialReason',
            'online',
            'wed',
            'thu',
            'fri',
            'sat',
            'country',
            'language',
            'firstName',
            'lastName',
            'mail',
            'phoneNumber'
        ];
        const data: any[] = [];
        const loweredNames: string[] = [];
        let skippedRows = 0;

        rowsLoop:
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const val = sheet[xlsx.utils.encode_cell({ c: C, r: R })];
                if (!val || val.v === '' || R < 2) continue;
                const rowIndex = R - 2 - skippedRows;

                // Skip row if startup already exists
                if (C === 0) {
                    const loweredValue = val.v.toLowerCase();
                    if (startups.some(s => s.spName.toLowerCase() === loweredValue) || loweredNames.some(s => s === loweredValue)) {
                        skippedRows++;
                        continue rowsLoop;
                    }
                    else {
                        loweredNames.push(loweredValue);
                    }
                }

                if (!data[rowIndex]) {
                    data[rowIndex] = {};
                }

                // If columns related to presence days
                if (C > 0 && C < 6) {
                    if (!data[rowIndex].daysPresences) {
                        data[rowIndex].daysPresences = [];
                    }
                    if (val.v === 'Yes') {
                        data[rowIndex].daysPresences?.push(labels[C]);
                    }
                }
                // If columns related to contact
                else if (C > 5 && C !== 7) {
                    if (!data[rowIndex].contact) {
                        data[rowIndex].contact = {};
                    }
                    const trimmedVal = `${val.v}`.trim();
                    if (C === 10) // email, check validity
                    {
                        if (trimmedVal.match(/^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/gm)) 
                            data[rowIndex].contact[labels[C]] = trimmedVal;
                        else
                            console.log(`${trimmedVal} is not a valid email`)
                    }
                    else 
                        data[rowIndex].contact[labels[C]] = trimmedVal;
                }
                // Language (not used anymore)
                else if (C === 7) {
                    /*if (val.v)
                        data[rowIndex].language = val.v.toLowerCase();
                    else
                        data[rowIndex].language = "en";
                    */
                }
                else {
                    data[rowIndex][labels[C]] = val.v;
                }
            }
        }
        return data.filter(r => !!r);
    }

    const handleImportSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const file = formData.get('fileImport');
        const data = await getFileData(file as File);

        const result = xlsx.read(data, { type: 'array' });
        const sheet = Object.values(result.Sheets)[0] as any;
        let newSheetData = sheet["H2"].v.indexOf('Language') < 0;

        const rawDataSheet = newSheetData ? getSheetData(sheet) : getOldSheetData(sheet);

        // filter to get only valid startups & raise an error if some have been removed
        const dataSheet = (rawDataSheet as CreateStartupRequestDto[])
            .filter((d) =>
                d.contact &&
                d.contact.country &&
                d.contact.firstName &&
                d.contact.lastName &&
                d.contact.mail &&
                d.contact.phoneNumber &&
                d.daysPresences &&
                d.socialReason);

        if (dataSheet.length === 0) {
            setFileImport(null);
            toast.error(t('dashboard.errors.importmissingfields'));
        }
        else {
            if (dataSheet.length < rawDataSheet.length) {
                toast.error(t('dashboard.errors.importmissingfields'));
                setFileImport(null);
            }
            else {
                startImportLoading();
                Promise.all(
                    dataSheet.map((row: any) =>
                        createStartup('save', {
                            ...row,
                            source: Source.Excel,
                            ParentExhibitorId: exhibitorId
                        })
                    )
                )
                    .then(results => {
                        const newStartups = results.map(r => r.data);
                        setStartups(startups.concat(newStartups));
                        toast.success(`${t('dashboard.successes.importfromfile')} ${(file as File).name}`);
                    }).catch(() => {
                        toast.error(t('dashboard.errors.import'));
                    }).finally(() => {
                        setFileImport(null);
                        stopImportLoading()
                    });
            }
        }
    };

    const isImportValid = useMemo(() => {
        if (!fileImport || fileImport.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return {
                valid: false,
                message: t('dashboard.errors.invalidExcelFile')
            };
        }
        return {
            valid: true,
            message: undefined
        };
    }, [fileImport, t]);

    return (
        <div className="bloc-iframe">
            <h2>{t('dashboard.title')}</h2>
            <button
                className="language-switcher"
                type="button"
                onClick={() => changeLanguageHandler(i18n.language === "en" ? "fr" : "en")}>{t("sessions.switchLanguage")}</button>

            {!isFetchLoading && exhibitor && exhibitor.sf_startups__c && (<>
                <div className="is-flex gap margin" style={{ flexWrap: "wrap", justifyContent: "space-between" }}>
                    <Link
                        className="button maintheme"
                        role='button'
                        to={`/startup/form?exhibitorId=${exhibitorId}&canPublish=${canPublishNewStartup()}`}
                        state={
                            {
                                existingStartupNames: startups?.map(s => s.spName.toLowerCase()),
                                startup: undefined
                            } as IStartupFormState
                        }
                    >
                        <i className="fas fa-plus"></i>
                        {t('common.addStartup')}
                    </Link>
                    {exhibitor.ag_Challenges && exhibitor.ag_Challenges.length > 0 && (
                        <Link className="button maintheme" 
                            role='button'
                            to={`/startups/import?exhibitorId=${exhibitorId}&selectedAgorizeIds=${startups.filter(s => s.spSource === 'Agorize').map(s => s.spSourceId)}`}>
                            <i className="fas fa-file-import"></i>
                            {t('common.agorizeImport')}
                        </Link>
                    )}

                    <form onSubmit={handleImportSubmit} className="is-flex">
                        <FileInput
                            label={t('dashboard.importStartups')}
                            name="fileImport"
                            onChange={setFileImport}
                            value={fileImport}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            placeholder={t('dashboard.fileInputPlaceholder')}
                        />
                        <Button
                            className="button maintheme"
                            label={t('common.submit')}
                            title={isImportValid.message}
                            type="submit"
                            disabled={!isImportValid.valid}
                            isLoading={isImportLoading}
                        />
                    </form>

                    <div>
                        <a className="button maintheme maintheme-light" href="MatriceInfosStartupsImport.xlsx" download>
                            <span className="text"><i className="fas fa-file-excel" style={{ marginRight: 10 }}></i> {t('dashboard.downloadTemplate')}</span>
                            <span className="overlay"></span>
                        </a>
                    </div>
                </div>

                <div className="margin startup-info">
                    <p style={{ marginTop: '15px' }}><b>{t('dashboard.startupsStatus')} ({t('dashboard.startupsQuota')} {exhibitor?.sf_startups__c}) :</b>&nbsp;
                        {t('dashboard.startupsCreated')} {currentStartupsInDraft},&nbsp;
                        {t('dashboard.startupsWaitingForApproval')} {currentStartupsWaitingForApproval},&nbsp;
                        {t('dashboard.startupsPublished')} {currentStartupsPublished}
                    </p>
                    <p style={{ marginTop: '10px', marginBottom: '5px' }} dangerouslySetInnerHTML={{ __html: t('dashboard.importExcelAllColumnsDescription') }}></p>
                    <p style={{ marginBottom: '15px' }} dangerouslySetInnerHTML={{ __html: t('dashboard.importExcelAreDraftDescription') }}></p>
                    {(!canPublishNewStartup || !exhibitor?.sf_startups__c) && (
                        <p className="notification warning">
                            <i className="fas fa-exclamation-triangle"></i>
                            {t('dashboard.cannotAddStartups')}
                        </p>
                    )}
                </div>
            </>)}

            {isFetchLoading ? (
                <TailSpin
                    color="#1d6ff7"
                    height={50}
                    width={50}
                    wrapperClass="loader margin"
                />
            ) : startups.length > 0 ? (
                <table className="table table-startups" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} >
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps({
                                        style: { minWidth: column.minWidth, width: column.width },
                                    })}>
                                        <span>{column.render('Header')}</span>
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            ) : areStartupsFetched && (
                <p className="text-center">{t('dashboard.noStartups')}</p>
            )
            }

            {isOpenDeleteModal && (
                <Modal>
                    <p>{t('dashboard.deleteConfirm')}</p>
                    <div className="buttons">
                        <button className="button button-light" onClick={closeDeleteModal}>
                            <i className="fas fa-times"></i>
                            {t('common.no')}
                        </button>
                        <button className="button maintheme" onClick={handleDeleteConfirm}>
                            <i className="fas fa-check"></i>
                            {t('common.yes')}
                        </button>
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default StartupsDashboard;